import React, { ForwardedRef, forwardRef } from "react";
import { combineClassNames, reactChild } from "../utils/reactHelpers";
import "./Grid.scss";

interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  gridRows?: string;
  gridColumns?: string;
  contentHeight?: boolean;
  gap?: string | true;
  rowGap?: string | true;
  columnGap?: string | true;
  padding?: boolean;
}

interface GridItemProps extends React.HTMLAttributes<HTMLDivElement> {
  scrollable?: boolean;
  padding?: boolean | string;
  paddingHorizontal?: boolean;
  paddingVertical?: boolean;
  rowTemplate?: string;
  colTemplate?: string;
  row?: string;
  column?: string;
  lineSeparator?: "bottom" | "top" | "right" | "left";
  contentAlign?: "right" | "center" | "left";
  alignSelf?: "center";
}

export function Grid(props: GridProps) {
  const { gridRows, gridColumns, contentHeight, gap, rowGap, columnGap, className, padding, style, ...restAttrs } =
    props;

  const cls = combineClassNames(
    "grid-layout",
    contentHeight ? "content-height" : "full-height",
    padding && "padding",
    className
  );
  const finalStyle = Object.assign({}, style);

  finalStyle.gridTemplateRows = getOrInferGridTemplateRows(props);
  finalStyle.gridTemplateColumns = getOrInferGridTemplateColumns(props);

  if (gap) finalStyle.gap = gap === true ? "16px" : gap;
  if (rowGap) finalStyle.rowGap = rowGap === true ? "16px" : rowGap;
  if (columnGap) finalStyle.columnGap = columnGap === true ? "16px" : columnGap;

  if (finalStyle.gridTemplateColumns && !finalStyle.gridTemplateRows) {
    finalStyle.gridTemplateRows = "1fr";
  }

  return <div style={finalStyle} className={cls} {...restAttrs} />;
}

export const GridItem = forwardRef(function GridItem(props: GridItemProps, ref: ForwardedRef<HTMLDivElement>) {
  const {
    padding,
    paddingHorizontal,
    paddingVertical,
    scrollable,
    className,
    contentAlign,
    alignSelf,
    rowTemplate: gridRowTemplate,
    colTemplate: gridColTemplate,
    lineSeparator,
    style,
    row,
    column,
    ...restAttrs
  } = props;

  const cls = combineClassNames(
    "grid-layout-item",
    padding && "padding",
    paddingHorizontal && "padding-horizontal",
    paddingVertical && "padding-vertical",
    scrollable && "scrollable",
    lineSeparator ? `line-separator-${lineSeparator}` : undefined,
    contentAlign ? `content-align-${contentAlign}` : undefined,
    alignSelf ? `align-self-${alignSelf}` : undefined,
    className
  );

  const itemStyle: React.CSSProperties = {};

  if (row) itemStyle.gridRow = row;
  if (column) itemStyle.gridColumn = column;
  if (typeof padding === "string") itemStyle.padding = padding;

  const finalStyle = Object.assign({}, itemStyle, style);

  return <div style={finalStyle} ref={ref} className={cls} {...restAttrs} />;
});

function getOrInferGridTemplateRows(props: GridProps): string {
  if (props.gridRows) return props.gridRows;

  const rows: string[] = [];
  React.Children.forEach(props.children, c => {
    if (!c) return;

    if (isGridItemElement(c) && c.props.rowTemplate) {
      rows.push(c.props.rowTemplate);
    }
  });

  return rows.join(" ");
}

function getOrInferGridTemplateColumns(props: GridProps): string {
  if (props.gridColumns) return props.gridColumns;

  const cols: string[] = [];
  React.Children.forEach(props.children, c => {
    if (!c) return;

    if (isGridItemElement(c) && c.props.colTemplate) {
      cols.push(c.props.colTemplate);
    }
  });

  return cols.join(" ");
}

function isGridItemElement(el: React.ReactNode): el is React.ReactElement<GridItemProps> {
  return reactChild.isReactElement(el) && el.type === GridItem;
}
