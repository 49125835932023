import { lazy } from "react";
import { tx } from "core/intl";
import { useInitiallyMaximizedWindow } from "core/desktop/WindowViewContext";
import { SystemState } from "core/global/systemState";
import { defineWindowDetails, useInitWindowSize } from "core/desktop";
import { WindowLazyContent } from "core/desktop/WindowLazyContent";

type MapWindowProps = React.ComponentProps<typeof MapWindowContent>;

const MapWindowContent = lazy(() => import("./MapWindowContent").then(m => ({ default: m.MapWindowContent })));

defineWindowDetails(MapWindow, {
  icon: "fas fa-map-marked-alt",
  title: tx("Mapa"),
  contextHelpCode: "MapWindow",
  noPadding: true,
  allowInPresentationMode: true,
  isAvailable: isMapWindowAvailable,
});

export function MapWindow(props: MapWindowProps) {
  useInitWindowSize(1000, 700);
  useInitiallyMaximizedWindow();

  return (
    <WindowLazyContent>
      <MapWindowContent {...props} />
    </WindowLazyContent>
  );
}

function isMapWindowAvailable(ss: SystemState) {
  const { privs } = ss;
  return (
    privs.itcView ||
    privs.busView ||
    privs.spvView ||
    privs.trafficCamView ||
    privs.chargingStationView ||
    privs.parkingSystemView ||
    privs.flowView ||
    privs.meteoStationView ||
    privs.outputDeviceView ||
    false
  );
}
